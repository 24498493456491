import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
//import Carousel from "react-multi-carousel";
import Card from './Card';
import ReactCardSlider from "react-card-slider-component";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { Image } from 'semantic-ui-react';
//import { Link } from 'gatsby'
//import starIcon from '../../assets/images/star-icon.png'
//import bnj from '../../assets/images/miningicon.png'
//import bna from '../../assets/images/tradingicon.png'
//import sss from '../../assets/images/tradingicon.png'
//import nusatuna from '../../assets/images/tradingicon.png'
//import energy from '../../assets/images/tradingicon.png'
import Trading from "../../assets/images/slide/trading.png"
import Fisheries from "../../assets/images/slide/trading.png"
import Fmcg from "../../assets/images/slide/trading.png"
import Energy from "../../assets/images/slide/trading.png"
import Fleet from "../../assets/images/slide/trading.png"

const slides = [
    {
      image: `${Trading}`,
      title: "Trading",
      description: "PT. Maharaja Nusantara Energi"
      // clickEvent: sliderClick
    },
    {
      image: `${Fisheries}`,
      title: "Fisheries",
      description: "PT. Ulam Nusantara Abadi"
      // clickEvent: sliderClick
    },
    {
      image: `${Fmcg}`,
      title: "FMCG",
      description: "PT. Selera Maharaja Nusantara"
      // clickEvent: sliderClick
    },
    {
      image: `${Energy}`,
      title: "Renewable Energy",
      description: "PT. Maha Raja Energi"
      // clickEvent: sliderClick
    },
    {
      image: `${Fleet}`,
      title: "Fleet",
      description: "PT. Bumi Nusantara Armada"
      // clickEvent: sliderClick
    }
  ];

/*const images = [
    "https://buminusagroup.co.id/upload/trading.jpg",
    "https://buminusagroup.co.id/upload/trading.jpg",
    "https://buminusagroup.co.id/upload/trading.jpg",
    "https://buminusagroup.co.id/upload/trading.jpg",
    "https://buminusagroup.co.id/upload/trading.jpg",
];

const texts = [
    "TRADING",
    "FISHERIES",
    "FMCG",
    "ENERGY",
    "FLEET"

];
const fakerData = Array(5)
    .fill(0)
    .map((item, index) => {
        return {
            image: images[index],
            headline: texts[index] || texts[0],
            description: texts[index] || texts[0]
        };
    });
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};*/


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ImgSlide = [
    `${Trading}`, `${Fisheries}`, `${Fmcg}`, `${Energy}`, `${Fleet}`
]

const MenuSlide = () => {

    const [isMoving, setisMoving] = useState(false)


   





    return (

        <div className='menu-body-slide'>
            <Helmet>
                <title>Bumi Nusantara Group</title>
                <meta charSet='utf-8' />
                <meta name='title' content='Bumi Nusantara Group' />
                <meta name="description" content="Bumi Nusantara Group" />
                <meta name='keywords' content='Bumi Nusantara Group, Bumi Nusantara Jaya, Bumi Nusantara Bara, Maharaja Mineral, Energi, Batu Bara, Biji Besi, Nikel, Iron Ore, BNJ, BNG, BNB, MRM, Trading, Binaia Energi Nusantara, Maha Raja Energi, Adimitra Hidro Nusantara, Arta Samudra Petroleum' />
                <meta name='author' content='BNG' />
                <meta property='og:locale' content='en_US' />
                <meta property='og:type' content='website' />
                <meta property='og:url' content='https://buminusa.co.id' />
                <meta property='og:url' content='https://buminusagroup.co.id' />
                <meta property='og:site_name' content='Bumi Nusantara Group' />
                <meta property='article:publisher' content='Get info PT. Ulam Nusantara Abadi' />
                <meta property='og:title' content='Bumi Nusantara Group' />
                <meta property='og:description' content='Welcome Bumi Nusantara Group' />
                <meta name="og:title" property="og:title" content="Bumi Nusantara Group"></meta>
                <meta name="twitter:card" content="Bumi Nusantara Group"></meta>
                <link rel="canonical" href="https://buminusa.co.id"></link>
                <link rel='canonical' href='https://buminusagroup.co.id'></link>
                <meta property="og:image" content="https://buminusagroup.co.id/upload/bnjgroup.png" />
            </Helmet>
            <div>
                <div className='row' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className='col-lg-4 col-sm-4'>
                        <p style={{ color: '#FFFFFF', textAlign: 'center', marginTop: '-20px', fontSize: '40px' }}>Our Industries</p>
                        <p style={{ color: '#FFFFFF', textAlign: 'justify', fontSize: '20px', marginLeft: '30px', marginRight: '30px' }}>We are proud to own and operate a diverse range of businesses across multiple industries, including energy, manufacture, fisheries, and many more. Our subsidiaries are fast-growing and committed to delivering high-quality products and services to their customers.</p>
                    </div>
                   
                    
                    <div className='col-lg-8 col-sm-8'>
                        <div style={{marginLeft: '20px'}}>
                        {/*<ReactCardSlider slides={slides}/>*/}
                        <Carousel
                                    responsive={responsive}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={false}
                                    infinite={true}
                                    arrows={true}
                                    autoPlay={true}
                                    autoPlaySpeed={5000}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    transitionDuration={500}
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    itemClass='carouselItem'
                                >
                                    {ImgSlide.slice(0, 5).map(img => {
                                        return (
                                            <Image
                                                draggable={false}
                                                style={{ width: "100%", height: "100%" }}
                                                src={img} />
                                        )
                                    })}
                                </Carousel>
                        </div>
                        {/*<>
                        <Carousel
                            autoPlay={true}
                            rewind={true}
                            responsive={responsive}
                            ssr
                            infinite={false}
                            beforeChange={setisMoving(true)}
                            afterChange={setisMoving(false)}
                            containerClass="first-carousel-container container">
                            {fakerData.map(card => {
                                <Card isMoving={isMoving} {...card} />;
                            })}

                        </Carousel>
                        </>*/}


                    </div>
                </div>
            </div>

        </div>
    )

}

export default MenuSlide;