import React from 'react'
import { Helmet } from 'react-helmet'
//import { Link } from 'gatsby'
//import starIcon from '../../assets/images/star-icon.png'
//import bnj from '../../assets/images/miningicon.png'
//import bna from '../../assets/images/tradingicon.png'
//import sss from '../../assets/images/tradingicon.png'
//import nusatuna from '../../assets/images/tradingicon.png'
//import energy from '../../assets/images/tradingicon.png'

const OurSolutions = () => {


    return (
    
        <div className='container'>
            <Helmet>
                <title>Bumi Nusantara Group</title>
                <meta charSet='utf-8' />
                <meta name='title' content='Bumi Nusantara Group' />
                <meta name="description" content="Bumi Nusantara Group" />
                <meta name='keywords' content='Bumi Nusantara Group, Bumi Nusantara Jaya, Bumi Nusantara Bara, Maharaja Mineral, Energi, Batu Bara, Biji Besi, Nikel, Iron Ore, BNJ, BNG, BNB, MRM, Trading, Binaia Energi Nusantara, Maha Raja Energi, Adimitra Hidro Nusantara, Arta Samudra Petroleum' />
                <meta name='author' content='BNG' />
                <meta property='og:locale' content='en_US' />
                <meta property='og:type' content='website' />
                <meta property='og:url' content='https://buminusa.co.id' />
                <meta property='og:url' content='https://buminusagroup.co.id' />
                <meta property='og:site_name' content='Bumi Nusantara Group' />
                <meta property='article:publisher' content='Get info PT. Ulam Nusantara Abadi' />
                <meta property='og:title' content='Bumi Nusantara Group' />
                <meta property='og:description' content='Welcome Bumi Nusantara Group' />
                <meta name="og:title" property="og:title" content="Bumi Nusantara Group"></meta>
                <meta name="twitter:card" content="Bumi Nusantara Group"></meta>
                <link rel="canonical" href="https://buminusa.co.id"></link>
                <link rel='canonical' href='https://buminusagroup.co.id'></link>
                <meta property="og:image" content="https://buminusagroup.co.id/upload/bnjgroup.png" />
            </Helmet>
            <div className='mainbody-banner'>
                <h1>Bumi Nusantara Group</h1>
                    <h1a>Welcome to Bumi Nusantara Group, a leading holding company that manages diverse companies across various industries. Our mission is to create long-term value for our partners and stakeholders by identifying and acquiring businesses with strong growth potential and supporting their development. </h1a>
            </div>
        </div>
        
    )
}

export default OurSolutions;